// imports
@import '../../styles/variables.scss';

.socialContainer {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin: 0 0 1rem;
}
.socialButton {
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: $BORDER_RADIUS0;
  transition: $TRANSITION1;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
  & svg {
    height: 1.2rem;
  }
  &.google {
    background: #ffffff;
    &:hover {
      background: $GREY1;
    }
  }
  &.facebook {
    background: #166fe5;
    &:hover {
      background: #1160c7;
    }
  }
  &.guest {
    background: #dedede;
    &:hover {
      background: $GREY2;
    }
  }
}
