// imports
@import '../../styles/variables.scss';

.inputGroup {
  position: relative;
  margin: 0 0 1rem;
}
.inputText {
  height: 45px;
  display: block;
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 12px 4px;
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  transition: 0.3s ease;
  border-radius: $BORDER_RADIUS1;
  background: $GREY1;
}
.inputText__label {
  position: absolute;
  top: 16px;
  left: 21px;
  cursor: text;
  will-change: left;
  transition: 0.3s, left 0.75s;
  color: $GREY5;
  font-size: $FONT_SIZE_1;
  font-weight: $FONT_WEIGHT_BOLD;
  letter-spacing: $LETTER_SPACING_1;
  user-select: none;
  & span {
    transition: 0.4s ease;
    display: inline-block;
    will-change: transform;
    // calculating transition delays
    $var: 0.04;
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: #{$var}s;
        $var: $var + 0.04;
      }
    }
  }
}
.animatedLabel {
  transform: translate(-8px, -14px);
  font-size: $FONT_SIZE_0;
}
