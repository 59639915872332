// imports
@import '../../styles/variables.scss';

.pickerContainer {
  position: relative;
}
.pickerOverlay {
  width: 9rem;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $GREY1;
  margin: 0;
  padding: 1rem;
  border-radius: $BORDER_RADIUS1;
  overflow: hidden;
  z-index: 1000;
}
.pickerOverlay li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.6rem;
  margin: 0 1rem 0 0;
  cursor: pointer;
  transition: $TRANSITION1;
  transition-property: background, color;
  border-radius: $BORDER_RADIUS1;
  font-weight: $FONT_WEIGHT_BOLD;
  color: $GREY4;
  user-select: none;
  &:hover {
    color: $GREY5;
    background: $WHITE;
  }
  &.active {
    background: rgba(255, 255, 255, 0.75);
  }
}
