// imports
@import "../../styles/variables.scss";

// button
.button {
  width: 9rem;
  display: flex;
  align-items: center;
  background: $PRIMARY;
  padding: 0.75rem 1.2rem;
  margin: 0.2rem 0;
  text-decoration: none;
  border-radius: $BORDER_RADIUS1;
  border: none;
  font-size: $FONT_SIZE_3;
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_BOLD;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: $TRANSITION1;
  user-select: none;
  box-shadow: 1px 1px 10px 1px grey;
  &:hover {
    background: $PRIMARYHIGHLIGHT;
  }
  &.plain {
    justify-content: center;
  }
}
.button:focus {
  outline: none;
  box-shadow: none;
}
.button__icon {
  height: 1.6rem;
  margin-right: 1rem;
}
.buttonPrev {
  background: $WHITE;
  color: $GREY5;
  &:hover {
    background: $GREY1;
  }
}
.buttonAddDate {
  background: $PRIMARY;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.icon {
  width: 20px;
}
