// imports
@import "../../styles/variables.scss";

.profile__container {
  display: grid;
  flex-direction: row;
  // align-items: center;

  grid-template-columns: 1fr 2.5fr;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
  transition: $TRANSITION1;
  user-select: none;
  scroll-padding: 10rem;
  &:hover {
    background: $GREY1;
  }
}
