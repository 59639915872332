// imports
@import '../../styles/variables.scss';

.loginFormContainer {
  height: 100%;
  grid-column: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: $GREY0;
  box-shadow: 2rem 0 3rem rgba(0, 0, 0, 0.15);
  z-index: 99999;
  padding: 0 30px;
  text-align: center;
  & .logo--light {
    max-width: 9rem;
    margin-bottom: 3rem;
  }
  & h2 {
    font-size: $FONT_SIZE_3;
  }
  & a {
    margin: 0 0 1rem;
    color: $GREY5;
    font-size: $FONT_SIZE_1;
    font-weight: $FONT_WEIGHT_BOLD;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: $LETTER_SPACING_1;
    transition: $TRANSITION1;
    &:hover {
      color: $BLACK;
    }
  }
}
#loginForm {
  width: 80%;
  display: flex;
  flex-direction: column;
}
#loginSubmit {
  width: 5rem;
  height: 5rem;
  margin: 2.4rem 0 2.4rem;
  background: $GREY1;
  border: none;
  border-radius: $BORDER_RADIUS1;
  cursor: pointer;
  transition: $TRANSITION1;
  & svg {
    width: 3rem;
    position: relative;
    top: 3px;
  }
}
.submitProg50 {
  background: $PRIMARYO50 !important;
}
.submitProg100 {
  background: $PRIMARY !important;
}
.storeIconGoogle {
  max-width: 18rem;
}
.storeIconApple {
  padding: 5%;
  max-width: 18rem;
}
