// imports
@import '../styles/variables.scss';

.loginContainer {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

// layout mobile
.m-loginContainer {
  height: 100vh;
  width: 100%;
}
// layout tablet
.t-loginContainer {
  height: 100vh;
  width: 100%;
}
.t-loginContent__grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
