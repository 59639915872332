// imports
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700;900&family=Ovo&display=swap');

// fonts
$FONT_FAMILY_REGULAR_1: 'Mulish', 'HelveticaNeue-Light', 'Helvetica Neue Light',
  'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;

$FONT_SIZE_0: 0.5rem;
$FONT_SIZE_1: 0.65rem;
$FONT_SIZE_2: 0.85rem;
$FONT_SIZE_3: 1rem;
$FONT_SIZE_4: 1.2rem;

$FONT_WEIGHT_REGULAR: 400;
$FONT_WEIGHT_BOLD: 600;
$FONT_COLOR_BLACK: #262626;

$LETTER_SPACING_1: 3px;

// colors
$PRIMARY: rgba(237, 52, 36, 1);
$PRIMARYO50: rgba(237, 52, 36, 0.5);

$PRIMARYHIGHLIGHT: rgba(255, 21, 0, 1);

$GREEN: rgba(32, 241, 14, 1);

$WHITE: #ffffff;
$BLACK: #1a1a1a;

$GREY0: rgba(251, 251, 251, 1);
$GREY1: rgba(242, 242, 242, 1);
$GREY2: rgba(210, 210, 210, 1);
$GREY3: rgba(190, 190, 190, 1);
$GREY4: rgba(160, 160, 160, 1);
$GREY5: rgba(120, 120, 120, 1);
$GREY6: rgba(51, 51, 51, 1);

// gradients

// shadows
$S_1: rgba(0, 0, 0, 0.15);

// box shadows
$BS_1: 3px 3px 6px 6px rgba(0, 0, 0, 0.05);

// borders
$BORDER1: 2px solid $WHITE;
$BORDER7: 2px solid $GREY6;
$BORDER_RADIUS0: 8px;
$BORDER_RADIUS1: 12px;
$BORDER_RADIUS2: 1.2rem;

// transitions
$TRANSITION1: all 0.3s ease-in-out;

// ui elements
$UISIZE1: 1.2rem;
$UISIZE2: 1.4rem;
$UISIZE3: 2.8rem;
$UIRADIUS1: 0.4rem;
$OPACITYLOW: 0.35;
$OPACITYFULL: 1;

// z indice
$zindex: (
  modal: 9000,
  overlay: 8000,
  dropdown: 7000,
  mobile-sidebar: 6000,
  header: 5000,
  bottom-tabs: 4000,
);
