@import "../../styles/variables.scss";
.no_meeting {
  color: $GREY3;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: $FONT_SIZE_2;
}
.closePlaceButton {
  width: 20px;

  pointer-events: all;
  z-index: 999999999;
}
