// imports
@import '../../styles/variables.scss';

.loginImgContainer {
  grid-column: 2 / 5;
}
.login__Crossfade {
  height: 100%;
  & > div {
    position: absolute;
    width: 75%;
    height: 100%;
    animation: imageAnimation 36s linear infinite 0s;
    background-size: cover;
    background-position: center center;
    margin: 0;
    opacity: 0;
    z-index: 0;
  }
  & > div:nth-child(1) {
    background-image: url('../../assets/img/meetus-occasion-bar.jpg');
  }
  & > div:nth-child(2) {
    animation-delay: 6s;
    background-image: url('../../assets/img/meetus-occasion-restaurant.jpg');
  }
  & > div:nth-child(3) {
    animation-delay: 12s;
    background-image: url('../../assets/img/meetus-occasion-cafe.jpg');
  }
  & > div:nth-child(4) {
    animation-delay: 18s;
    background-image: url('../../assets/img/meetus-occasion-club.jpg');
  }
  & > div:nth-child(5) {
    animation-delay: 24s;
    background-image: url('../../assets/img/meetus-occasion-coworking.jpg');
  }
  & > div:nth-child(6) {
    animation-delay: 30s;
    background-image: url('../../assets/img/meetus-occasion-random.jpg');
  }
}
@keyframes imageAnimation {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    background-size: 100%;
  }
  8% {
    animation-timing-function: ease-out;
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
    background-size: 105%;
  }
  100% {
    opacity: 0;
  }
}
