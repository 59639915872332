// imports
@import '../../styles/variables.scss';

.tabNav__bar {
  width: 100vw;
  height: 90px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: $WHITE;
  box-shadow: $BS_1;
  padding: 20px 28px;
}
.tabNav__container {
  display: flex;
  justify-content: space-between;
}
.tabnav__item {
  width: 30%;
  max-width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_BOLD;
  border-radius: $BORDER_RADIUS1;
  color: $GREY4;
  text-decoration: none;
}
.tabnav__item--active {
  color: $PRIMARY;
  transition: $TRANSITION1;
  &:hover {
    background: $PRIMARYHIGHLIGHT;
  }
}
.tabNav__icon {
  width: $UISIZE2;
}
