// imports
@import '../../styles/variables.scss';

header {
  width: 100%;
  height: 78px;
  position: fixed;
  top: 0;
  left: 0;
  background: $WHITE;
  padding: 0px 20px;
  box-shadow: $BS_1;
  z-index: map-get($zindex, header);
  overflow: hidden;
}
.header__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__menu {
  display: flex;
  align-items: center;
}
.header__title {
  font-size: $FONT_SIZE_4;
  margin: 0 0 0 1rem;
}
.header__menuButtton {
  width: $UISIZE2;
}
.header__options {
  display: flex;
  align-items: center;
}
.header__profile {
  width: $UISIZE2;
}
.header__notifications {
  width: $UISIZE2;
}
.sidebar__menu {
  position: fixed;
  top: 0;
  left: 0;
  & ul {
    width: 100%;
    max-width: 280px;
    height: 100%;
    position: fixed;
    top: 0;
    transform: translate(-100%, 0);
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
    margin: 0;
    z-index: map-get($zindex, mobile-sidebar);
  }
  &.active {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: $S_1;
    z-index: map-get($zindex, mobile-sidebar);
    transition: $TRANSITION1;
    & ul {
      width: 65%;
      max-width: 280px;
      height: 100%;
      top: 0;
      transform: translate(0, 0);
      background: $WHITE;
      padding-left: 0px;
      z-index: map-get($zindex, mobile-sidebar);
      box-shadow: $BS_1;
    }
  }
}

.option {
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vw;
  padding: 30px 0px;
}
