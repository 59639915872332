// imports
@import '../../styles/variables.scss';

.meterContainer {
  width: 80%;
  max-width: 600px;
  margin: 0 0 1rem;
}
.statusBar {
  width: 70%;
  height: 1px;
  position: relative;
  top: 11px;
  margin: auto;
  background: $GREY3;
  z-index: -100;
}
.currentStatus {
  max-width: 100%;
  height: 1px;
  background: $GREEN;
}
.stepsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}
.stepsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & h4 {
    margin: 0.8rem;
    color: $GREY3;
  }
}
.progressStep {
  width: 20px;
  height: 20px;
  position: relative;
  border: 2px solid $GREY3;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  &::after {
    position: absolute;
    left: 2px;
    top: 2px;
    content: '';
    display: inline-block;
    height: 6px;
    width: 9px;
    border-left: 2px solid $WHITE;
    border-bottom: 2px solid $WHITE;
    transform: rotate(-45deg);
    transition: $TRANSITION1;
  }
}
