// imports
@import "../../styles/variables.scss";

.meeting__details {
  color: $GREY3;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: $FONT_SIZE_2;
}
.participant {
  width: 9rem;
  display: flex;
  align-items: center;
  background: $GREY5;
  padding: 0.75rem 1.2rem;
  margin: 0.2rem 0;
  text-decoration: none;
  border-radius: $BORDER_RADIUS1;
  border: none;
  font-size: $FONT_SIZE_3;
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_BOLD;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: $TRANSITION1;
  user-select: none;
  &:hover {
    background: $PRIMARYHIGHLIGHT;
  }
  &.plain {
    justify-content: center;
  }
}
.participant_name {
  margin-left: 0.4rem;
}
.organizer {
  width: 9rem;
  display: flex;
  align-items: center;
  background: $PRIMARY;
  padding: 0.75rem 1.2rem;
  margin: 0.2rem 0;
  text-decoration: none;
  border-radius: $BORDER_RADIUS1;
  border: none;
  font-size: $FONT_SIZE_3;
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_BOLD;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: $TRANSITION1;
  user-select: none;
  &:hover {
    background: $PRIMARYHIGHLIGHT;
  }
  &.plain {
    justify-content: center;
  }
}
