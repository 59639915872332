// imports
@import "../../styles/variables.scss";

.day__container {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: $TRANSITION1;
  user-select: none;
  scroll-padding: 10rem;
  &:hover {
    background: $GREY1;
  }
}
.day--active {
  background: $GREY1;
  & .day__meta {
    color: $PRIMARY;
  }
}
.day__meta {
  text-align: center;
  & h3 {
    font-weight: 900;
    font-size: 32px;
    margin: 0;
  }
  & h4 {
    margin: 0;
  }
}
.day__separator {
  height: 1px;
  width: 90%;
  border-top: 2px solid $GREY2;
  margin: 1rem auto;
}
.day__meeting__count {
  text-align: center;
  border-left: 2px solid $GREY2;
  color: $FONT_COLOR_BLACK;
}
.day__meeting__no_meeting {
  text-align: center;
  border-left: 2px solid $GREY2;
  color: $GREY4;
}
