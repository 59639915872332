// imports
@import '../../styles/variables.scss';

.occasionsContainer {
  display: grid;
  grid-template-columns: 80px 80px 80px;
  grid-template-rows: 80px 80px;
  grid-gap: 10px;
}
.occasionItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: $BORDER_RADIUS1;
  background: $GREY1;
  font-size: $FONT_SIZE_2;
  font-weight: $FONT_WEIGHT_BOLD;
  color: $GREY6;
  user-select: none;
  cursor: pointer;
}
.occasionIcon {
  width: 45%;
  margin: 0 0 0.3rem;
}
