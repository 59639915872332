// imports
@import '../../styles/variables.scss';

// dropdown
.dropdown__container {
  position: relative;
  height: 60px;
  z-index: 1000;
}
.dropdown {
  width: 100%;
  position: absolute;
  background: $GREY1;
  padding: 0.75rem 1.2rem;
  text-decoration: none;
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_BOLD;
  line-height: 1.6rem;
  color: $GREY4;
  border-radius: $BORDER_RADIUS1;
  transition: $TRANSITION1;
  transition-property: color;
}
.dropdown__button {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  user-select: none;
  cursor: pointer;
  transition: $TRANSITION1;
  transition-property: color;
  &:hover {
    color: $GREY5;
  }
}
.dropdown__icon {
  height: 1.6rem;
  margin-right: 1rem;
}
.dropdown__toggle {
  height: 14px;
  margin-left: auto;
}
.dropdown ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.dropdown li {
  list-style: none;
  padding: 0.3rem 0.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: $TRANSITION1;
  transition-property: background, color;
  border-radius: $BORDER_RADIUS1;
  &:hover {
    color: $GREY5;
    background: $WHITE;
  }
  &.active {
    background: rgba(255, 255, 255, 0.75);
  }
}
